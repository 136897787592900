











import { Component, Prop, PropSync, Vue } from "vue-property-decorator";

@Component({})
export default class NumberSelect extends Vue {
  @Prop() numberOptions: number[]|undefined;
  @PropSync('number', { type: Number }) numberSync: number|undefined;

  get displayNumberOptions(): { text: string; value: number }[] | undefined {
    return this.numberOptions?.map((value) =>
      {
        if (value > -1)  {
          return { text: `Top ${value}`, value }
        } else {
          return { text: 'All', value }
        }
      }
    );
  }
}
