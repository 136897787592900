

















import DateSelect from "@/components/common/date-select.vue";
import dayjs from "dayjs";
  import { Component, PropSync, Vue, Watch } from "vue-property-decorator";

  @Component({  
    components: {
      DateSelect
  }})
  export default class DateRange extends Vue {
    @PropSync('from', { type: String }) fromDate: string|undefined;
    @PropSync('to', { type: String }) toDate: string|undefined;
    maxDays = 9999999999;

    @Watch('from')
    checkFrom() {
      const from = dayjs(this.fromDate).startOf('day');
      if (from > dayjs(this.toDate)) {
        this.toDate = this.fromDate;
      }
      if (dayjs(this.toDate).startOf('day').diff(from, 'days') > this.maxDays) {
        this.toDate = from.add(this.maxDays, 'day').format('YYYY-MM-DD');
      }
        
    }

    @Watch('to')
    checkTo() {
      const to = dayjs(this.toDate).startOf('day');
      if (to < dayjs(this.fromDate)) {
        this.fromDate = this.toDate;
      }
      if (to.diff(dayjs(this.fromDate).startOf('day'), 'days') > this.maxDays) {
        this.fromDate = to.subtract(this.maxDays, 'day').format('YYYY-MM-DD');
      }
        
    }
  }
