
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class TotalSheet extends Vue {
  @Prop(Number) total: number | undefined;
  @Prop(Number) withHits: number | undefined;
  @Prop(Date) lastUpdated: Date | undefined;
}
