











import { DateRange, dateRangeOptions } from "@/helpers/analytics";
import { Component, PropSync, Vue } from "vue-property-decorator";

@Component({})
export default class DateRangeSelect extends Vue {
  @PropSync('dateRange', { type: Number }) dateRangeSync: DateRange|undefined;

  get dateRangeOptions() {
    return dateRangeOptions;
  }
}
