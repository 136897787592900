











import { Granularity, granularityOptions } from "@/helpers/analytics";
import { Component, PropSync, Vue } from "vue-property-decorator";

@Component({})
export default class GranularitySelect extends Vue {
  @PropSync('granularity', { type: Number }) granularitySync: Granularity|undefined;

  get granularityOptions() {
    return granularityOptions;
  }
}
