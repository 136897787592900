import { DateLineChartData, DateRange, Granularity } from "@/helpers/analytics";
import { AnalyticsData, StatusStats } from "@/services/analytics-service";
import dayjs, { Dayjs } from "dayjs";
import Vue from "vue";
import config from "@/config";
import { Metadata, AllStatusesConfig } from "@/services/configuration-service";
import { isObject } from "lodash";

export enum CaseStatus {
  New = 0,
  InProgress = 1,
  Closed = 2
}

function getStatusText(value: number|CaseStatus) {
  for(const configStatus of config.caseStatus){
    if(configStatus.value === value){
      return configStatus.name;
    }
  }
  return "Unknown";
}

Vue.filter(
  'status',
  (value: number) => getStatusText(value)
);

function calculateDateRange(from: Dayjs, to: Dayjs) {
  const now = dayjs().utc();
  const calcTo = dayjs(to ? to : dayjs()).utc().add(1, 'day').subtract(1, 'second');
  return {
    from: dayjs(from ? from : new Date(1900, 0, 1)).utc().startOf("hour"),
    to: calcTo.isAfter(now) ? now : calcTo
  }
}

function GetSelectedConfig(config: AllStatusesConfig, status: CaseStatus[], propertyName: string) {

  const selectedConfig = config.statuses.filter(x => status.includes(x.id as number))

  const flatSelectedConfigs = selectedConfig.flatMap(x => x.metadata);

  return flatSelectedConfigs.find(x => x.name == propertyName);
}

function GetSelectedValues(values: AnalyticsSelectionValue[] | null, status: CaseStatus[], propertyName: string) {
  const selectedDropdown = values?.filter(x => status.includes(x.statusId));
  return selectedDropdown?.flatMap(x => x.metadata).find(x => x.name == propertyName);
}

function returnedResults(
  data: StatusStats[], 
  includeNew = false,
  includeInProgress = false,
  includeClosed = false
): DateLineChartData {
  const results = { datasets: [] } as DateLineChartData;

    results.datasets.push({
      label: getStatusText(CaseStatus.New),
      data: data.filter(x => x.Status == CaseStatus.New).map(x => ({ y: x.Total, x: x.Time })),
      fill: false,
      borderWidth: 1,
      borderColor: "rgb(255, 0, 0)",
      backgroundColor: "rgb(255, 0, 0, 0.3)",
      tension: 0.2,
      pointRadius: 0,
      hidden: !includeNew
    });


  if (includeInProgress) {
    results.datasets.push({
      label: getStatusText(CaseStatus.InProgress),
      data: data.filter(x => x.Status == CaseStatus.InProgress).map(x => ({ y: x.Total, x: x.Time })),
      fill: false,
      borderWidth: 1,
      borderColor: "rgb(128, 0, 255)",
      backgroundColor: "rgb(128, 0, 255, 0.3)",
      tension: 0.2,
      pointRadius: 0
    });
  }

  if (includeClosed) {
    results.datasets.push({
      label: getStatusText(CaseStatus.Closed),
      data: data.filter(x => x.Status == CaseStatus.Closed).map(x => ({ y: x.Total, x: x.Time })),
      fill: false,
      borderWidth: 1,
      borderColor: "rgb(0, 34, 255)",
      backgroundColor: "rgb(0, 34, 255, 0.3)",
      tension: 0.2,
      pointRadius: 0
    })
  }
  return results;
}

export function calculateCasesByStatusChartData(
  casesByStatusStats: AnalyticsData<StatusStats> | null,
  casesByStatusDateRange: DateRange
): DateLineChartData {
  if (casesByStatusStats == null) {
    return { datasets: [] };
  }

  const now = dayjs();
  const from = now.subtract(casesByStatusDateRange, "days");
  const compare = from.startOf('hour');

  let slice = casesByStatusStats.data.filter(x => compare.isSameOrBefore(x.Time));

  slice = slice
    .reduce(
      (arr, stat) => {
        const timestamp = dayjs(stat.Time)
          .startOf("hour")
          .toDate();
        const existing = arr.find(
          x => x.Time.getTime() == timestamp.getTime()
          && x.Status == stat.Status
        );
        if (existing) {
          existing.Total = stat.Total;
          return arr;
        }
        return [...arr, { ...stat, Time: timestamp }];
      },
      [] as StatusStats[]
    );

    return returnedResults(slice, true, true);
}

export function calculateCasesByMetadataChartData(
  casesByStatusStats: AnalyticsData<StatusStats> | null,
  casesByStatusDateRange: DateRange
): DateLineChartData {
  if (casesByStatusStats == null) {
    return { datasets: [] };
  }

  const now = dayjs();
  const from = now.subtract(casesByStatusDateRange, "days");
  const compare = from.startOf('hour');

  let slice = casesByStatusStats.data.filter(x => compare.isSameOrBefore(x.Time));

  slice = slice
    .reduce(
      (arr, stat) => {
        const timestamp = dayjs(stat.Time)
          .startOf("hour")
          .toDate();
        const existing = arr.find(
          x => x.Time.getTime() == timestamp.getTime()
          && x.Status == stat.Status
        );
        if (existing) {
          existing.Total = stat.Total;
          return arr;
        }
        return [...arr, { ...stat, Time: timestamp }];
      },
      [] as StatusStats[]
    );

    return returnedResults(slice, false, true);
}


export function calculateActivityChartData(
  activityStats: AnalyticsData<StatusStats> | null,
  fromDate: Dayjs,
  ToDate: Dayjs,
  activityGranularity: Granularity
): DateLineChartData {

  if (activityStats == null) {
    return { datasets: [] };
  }

  const { from, to } = calculateDateRange(fromDate, ToDate);

  const zeros = [] as StatusStats[];
  let date = from.startOf("hour");
  while (date.isSameOrBefore(to)) {
    zeros.push({
      Time: date.toDate(),
      Status: CaseStatus.InProgress,
      Total: 0
    });
    zeros.push({
      Time: date.toDate(),
      Status: CaseStatus.New,
      Total: 0
    });
    zeros.push({
      Time: date.toDate(),
      Status: CaseStatus.Closed,
      Total: 0
    });
    date = date.add(1, "hour");
  }

  const compare = from.startOf('hour');

  let slice = zeros.concat(
    activityStats.data.filter(x => compare.isSameOrBefore(x.Time))
  );

  slice = slice
    .reduce(
      (arr, stat) => {
        const timestamp = dayjs(stat.Time).utc()
          .startOf(activityGranularity == Granularity.Day ? "day" : "hour")
          .toDate();
        const existing = arr.find(
          x => x.Time.getTime() == timestamp.getTime()
          && x.Status == stat.Status
        );
        if (existing) {
          existing.Total += stat.Total;

          return arr;
        }
        return [...arr, { ...stat, Time: timestamp }];
      },
      [] as StatusStats[]
    );

    return returnedResults(slice, true, true, true);
}

export function calculateActivityChartDataDateRange(
  activityStats: AnalyticsData<StatusStats> | null,
  activityDateRange: DateRange,
  activityGranularity: Granularity
): DateLineChartData {


  const now = dayjs().utc().startOf("hour");
  const from = now.subtract(activityDateRange, "days");

  return calculateActivityChartData(
    activityStats,
    from,
    now,
    activityGranularity
  )
}


export interface StatusMetadataTableEntry {
  Name: string;
  Total: number;
}

function initializeZeroTable(
  foundConfig: Metadata,
  foundMetadata: MetadataSelectionValue
): StatusMetadataTableEntry[] {
  const zeros = [] as StatusMetadataTableEntry[];
  foundMetadata.values.forEach((foundMetadataValue) => {
    zeros.push({Name: foundMetadataValue, Total: 0} as StatusMetadataTableEntry)
  });
  foundConfig.values.forEach((foundConfigValue) => {
    if (!zeros.find(x => x.Name == foundConfigValue.value))
      zeros.push({ Name: foundConfigValue.value, Total: 0} as StatusMetadataTableEntry)
    });

  return zeros;
}

function lookupName(
  name: string, 
  config: Metadata | undefined
  ): string {
    if (name == '')
      return "(No Value Selected)";
    const found = config?.values.find(x => x.value == name)?.displayName;
    return found ? found : name + "*";
}

export function calculateMetadataTable(
  statusMetadataConfig: AllStatusesConfig,
  casesByMetadataStats: AnalyticsData<StatusStats> | null,
  metadataDropdownValues: AnalyticsSelectionValue[] | null,
  casesByMetadataFrom: Dayjs,
  casesByMetadataTo: Dayjs,
  casesByMetadataStatus: CaseStatus[],
  casesByPropertyAggregator: string,
  casesByPropertyFilter: string,
  casesByPropertyFilterValue: string
): StatusMetadataTableEntry[] {

  if (casesByMetadataStats == null) {
    return [];
  }
  const { from, to } = calculateDateRange(casesByMetadataFrom, casesByMetadataTo);

  let foundConfig = GetSelectedConfig(statusMetadataConfig, casesByMetadataStatus, casesByPropertyAggregator)

  if (!foundConfig)
    foundConfig = { type: "", name: "", values: [], isRequired: false };

  const foundDropdown = GetSelectedValues(metadataDropdownValues, casesByMetadataStatus, casesByPropertyAggregator)
  if (!foundDropdown) {
    return [];
  }

  const zeros = initializeZeroTable(foundConfig, foundDropdown);

  const slice = casesByMetadataStats.data
    .filter(x => casesByMetadataStatus.includes(x.Status))
    .reduce((countOfConfigValues, item) => {
      if (
        from.isSameOrBefore(item.Time)
        && to.isSameOrAfter(item.Time)
      ) {
        const foundMetadata = item.Metadata?.find(x => x.Key == foundDropdown?.name);

        function metadataDoesNotMatchFilter() {
          return !item.Metadata?.find(x => x.Key == casesByPropertyFilter && x.Value == casesByPropertyFilterValue);
        }

        if (foundMetadata) {
          if (casesByPropertyFilter && casesByPropertyFilterValue) {
            if (metadataDoesNotMatchFilter())
              return countOfConfigValues;
          }
          const existing = countOfConfigValues.find(x => x.Name == foundMetadata.Value);
          if (existing) {
            existing.Total += item.Total;
          }
        }
      }

      return countOfConfigValues;
    }, zeros)

  const results = slice
    .map(x => { 
      return { 
        ...x, 
        Name: lookupName(x.Name, foundConfig) 
      } 
    })
    .sort((a, b) => a.Name < b.Name ? -1 : 0)

  
  if(results.length > 0){
    const totalFields = results.reduce((a, b) => a += b.Total, 0)
    results.push({ Name: "Total", Total: totalFields } as StatusMetadataTableEntry)
  }
   
  return results;
}

export interface AllMetadataResults {
  Filter: string;
  Data: StatusMetadataTableEntry[];
}



export function calculateMetadataTableWithAllResults(
  statusMetadataConfig: AllStatusesConfig,
  casesByMetadataStats: AnalyticsData<StatusStats> | null,
  metadataDropdownValues: AnalyticsSelectionValue[] | null,
  casesByMetadataFrom: Dayjs,
  casesByMetadataTo: Dayjs,
  casesByMetadataStatus: CaseStatus[],
  casesByPropertyAggregator: string,
  casesByPropertyFilterValue: string
): AllMetadataResults[] {
  if (casesByMetadataStats == null) {
    return [];
  }
  const { from, to } = calculateDateRange(casesByMetadataFrom, casesByMetadataTo);

  let foundConfig = GetSelectedConfig(statusMetadataConfig, casesByMetadataStatus, casesByPropertyAggregator)

  if (!foundConfig)
    foundConfig = { type: "", name: "", values: [], isRequired: false };

  const selectedDropdown = metadataDropdownValues?.filter(x => casesByMetadataStatus.includes(x.statusId));
  const foundDropdown = selectedDropdown?.flatMap(x=>x.metadata).find(x => x.name == casesByPropertyAggregator);
  if (!foundDropdown) {
    return [];
  }

  const casesByPropertyFilterValues = selectedDropdown?.flatMap(x=>x.metadata).find(x => x.name == casesByPropertyFilterValue);
  if (!casesByPropertyFilterValues)
    return [];

  const mappedZeros = casesByPropertyFilterValues.values.map(filter => {
    return {
      Filter: filter,
      Data: initializeZeroTable(foundConfig as Metadata, foundDropdown)
    }
  })

  const slice = casesByMetadataStats.data
    .filter(x => casesByMetadataStatus.includes(x.Status))
    .reduce((summaryOfValues, item) => {
      if (from.isSameOrBefore(item.Time) && to.isSameOrAfter(item.Time)) {

        if (!item.Metadata) return summaryOfValues;
        if (item.Metadata?.length < 2) return summaryOfValues;

        const foundProperty = item.Metadata?.find(x => x.Key == foundDropdown?.name);
        const foundFilter = item.Metadata?.find(x => casesByPropertyFilterValues.name == x.Key && casesByPropertyFilterValues.values.includes(x.Value));

        if (!foundProperty || !foundFilter) return summaryOfValues;


        const existingFilter = summaryOfValues.find((x: any) => x.Filter == foundFilter.Value);
        const existingProperty = existingFilter?.Data.find((x: StatusMetadataTableEntry) => x.Name == foundProperty.Value);

        if (!existingFilter || !existingProperty) return summaryOfValues;

        existingProperty.Total += item.Total;
      }

      return summaryOfValues;

    }, mappedZeros)

  return slice
}

export function calculateMetadataChart(
  casesByMetadataStats: AnalyticsData<StatusStats> | null
): DateLineChartData {
  if (casesByMetadataStats == null) {
    return { datasets: [] };
  }

  return { datasets: [] } as DateLineChartData;
}

export interface MetadataSelectionValue {
  name: string;
  values: string[];
}

export interface AnalyticsSelectionValue {
  statusId: CaseStatus;
  metadata: MetadataSelectionValue[];
}

export function calculatePropertyValues(
  casesByMetadataStats: AnalyticsData<StatusStats> | null,
  casesByMetadataFrom: Dayjs,
  casesByMetadataTo: Dayjs,
): AnalyticsSelectionValue[] {
  if (casesByMetadataStats == null)
    return [] as AnalyticsSelectionValue[];

  const from = dayjs(casesByMetadataFrom ? casesByMetadataFrom : new Date(1900, 0, 1)).utc().startOf("day");
  const to = dayjs(casesByMetadataTo ? casesByMetadataTo: dayjs()).utc().endOf('day');
  let results = [
    {
      statusId: CaseStatus.New,
      metadata: []
    },
    {
      statusId: CaseStatus.InProgress,
      metadata: []
    },
    {
      statusId: CaseStatus.Closed,
      metadata: []
    }
  ] as AnalyticsSelectionValue[];

  casesByMetadataStats?.data.forEach((statusStats) => {
    if (!from.isSameOrBefore(statusStats.Time) || !to.isSameOrAfter(statusStats.Time)) return;

    const analyticsSelectionValue = results.find(x => x.statusId == statusStats.Status);

    statusStats.Metadata?.forEach((metadata) => {
      let existing = analyticsSelectionValue?.metadata.find(x => x.name == metadata.Key);

      if (existing == undefined) {
        existing = { name: metadata.Key, values: [] } as MetadataSelectionValue;
        analyticsSelectionValue?.metadata.push(existing);
      }

      if (!existing.values.filter(x => x == metadata.Value).length)
        existing.values.push(metadata.Value);
    });
  });

  results = results.map(result => {
    result.metadata = result.metadata.sort((a, b) => a.name < b.name ? -1 : 0);
    result.metadata.forEach(metadata => {
      metadata.values = metadata.values.sort((a, b) => a < b ? -1 : 0);
    });

    return result;
  });

  return results;
}


