























import { Component, Prop, Vue } from "vue-property-decorator";
import TotalSheet from "@/components/analytics/shared/total-sheet.vue";
import DateRangeSelect from "@/components/analytics/shared/date-range-select.vue";
import GranularitySelect from "@/components/analytics/shared/granularity-select.vue";
import NumberSelect from "@/components/analytics/shared/number-select.vue";
import { ScreeningStats, AnalyticsData } from "@/services/analytics-service";
import LineChart from "@/components/charts/LineChart.vue";
import { ChartData, ChartOptions } from "chart.js";
import { Granularity } from "@/helpers/analytics";
import { calculateScreeningsChartData } from "@/components/analytics/hazcheck-detect/hazcheck-detect-func";
import DateRange from "@/components/analytics/shared/date-range.vue";
import dayjs from "dayjs";

type DateLineChartData = ChartData<"line", { y: number; x: Date }[], Date>;

@Component({
  components: {
    LineChart,
    TotalSheet,
    DateRangeSelect,
    GranularitySelect,
    NumberSelect,
    DateRange
  }
})
export default class HazcheckDetectScreeningsChart extends Vue {
  @Prop() screenings: AnalyticsData<ScreeningStats> | null = null;
  @Prop() loading!: boolean;

  screeningsGranularity = Granularity.Hour;
  screeningsChartOptions: ChartOptions<"line"> = {
    plugins: {
      decimation: {
        enabled: true,
        algorithm: "lttb"
      }
    },
    interaction: {
      intersect: false,
      axis: "x"
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day"
        }
      }
    }
  };
  screeningsFrom = dayjs().startOf('day').subtract(7, 'days').format('YYYY-MM-DD');
  screeningsTo = dayjs().startOf('day').format('YYYY-MM-DD');
  maxDays = 120;

  get totalScreenings() {
    return this.screenings?.data?.reduce((a, b) => a + b.Screenings, 0);
  }

  get lastUpdated() {
    return this.screenings?.lastUpdated;
  }

  get screeningsChartData(): DateLineChartData {
    return calculateScreeningsChartData(
      this.screenings,
      new Date(this.screeningsFrom),
      new Date(this.screeningsTo),
      this.screeningsGranularity,
      this.maxDays
    );
  }
}
