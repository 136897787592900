






































































































































import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import AnalyticsService, { StatusStats, AnalyticsData } from "@/services/analytics-service";
import {
  calculateMetadataTable,
  StatusMetadataTableEntry,
  CaseStatus,
  calculatePropertyValues,
  AnalyticsSelectionValue,
  calculateMetadataTableWithAllResults,
  AllMetadataResults
} from "../case-management-func";
import LineChart from "@/components/charts/LineChart.vue";
import Config from "@/config";
import ConfigurationService, { AllStatusesConfig } from "@/services/configuration-service";

import DateRangeSelect from "../../shared/date-range-select.vue";
import GranularitySelect from "../../shared/granularity-select.vue";
import TotalSheet from "../../shared/total-sheet.vue";
import DateSelect from "@/components/common/date-select.vue"
import dayjs from "dayjs";
import CaseManagementMetadataDialog from "./case-management-metadata-dialog.vue";

@Component({
  components: {
    LineChart,
    TotalSheet,
    DateRangeSelect,
    GranularitySelect,
    DateSelect,
    CaseManagementMetadataDialog
  }
})
export default class CaseManagementMetadataTable extends Vue {
  @Inject() AnalyticsService!: AnalyticsService;
  @Inject() ConfigurationService!: ConfigurationService;

  loading = true;

  casesByMetadata: AnalyticsData<StatusStats> | null = null;
  casesByMetadataFrom = dayjs().subtract(7, 'days').format("YYYY-MM-DD");
  casesByMetadataTo = dayjs().format("YYYY-MM-DD");
  casesByMetadataStatus = CaseStatus.Closed;
  casesByMetadataStatuses = [CaseStatus.Closed]
  casesByMetadataAggregatorProperty = "";
  casesByMetadataFilterProperty = "";
  casesByMetadataFilterValue = "";
  viewLatest = false;

  async mounted() {
    await this.fetchAnalytics();
    await this.fetchConfiguration();
  }

  changeViewLatest(){
    this.fetchAnalytics();
  }

  async fetchAnalytics() {
    this.casesByMetadata = await this.FetchLatestOrHistory();
  }

  FetchLatestOrHistory(){
    if(!this.viewLatest) return this.AnalyticsService.readCasesByMetadata();
     return this.AnalyticsService.readCasesByMetadataLatest()   
  }

  dialog = false;
  metadataConfig: AllStatusesConfig = { statuses: [] };

  async fetchConfiguration() {
    this.metadataConfig = await this.ConfigurationService.getStatusMetadataConfig();
  }

  get metadataPropertyValues(): AnalyticsSelectionValue[] {

  const values = calculatePropertyValues(
      this.casesByMetadata,
      dayjs(this.casesByMetadataFrom),
      dayjs(this.casesByMetadataTo)
    )
    return values;
  }

  get metadataStatus(): StatusMetadataTableEntry[] {
    const results = calculateMetadataTable(
      this.metadataConfig,
      this.casesByMetadata,
      this.metadataPropertyValues,
      dayjs(this.casesByMetadataFrom).utc(),
      dayjs(this.casesByMetadataTo).utc(),
      this.viewLatest ? this.casesByMetadataStatuses :[this.casesByMetadataStatus],
      this.casesByMetadataAggregatorProperty,
      this.casesByMetadataFilterProperty,
      this.casesByMetadataFilterValue
    );

    return results;
  }

  get metadataTableWithAllProperties(): AllMetadataResults[] {
    if (!this.casesByMetadataAggregatorProperty || !this.casesByMetadataFilterProperty)
      return [];

    const results = calculateMetadataTableWithAllResults(
      this.metadataConfig,
      this.casesByMetadata,
      this.metadataPropertyValues,
      dayjs(this.casesByMetadataFrom),
      dayjs(this.casesByMetadataTo),
      this.viewLatest ? this.casesByMetadataStatuses :[this.casesByMetadataStatus],
      this.casesByMetadataAggregatorProperty,
      this.casesByMetadataFilterProperty,
    );

    return results;
  }

  get metadataTableHeaders(){
    return this.metadataTableWithAllProperties.map(x => { return x.Filter })
  }

  caseStatusItems = Config.caseStatus;

  get filteredStatusMetadataPropertyValues(): AnalyticsSelectionValue | undefined {
    return this.metadataPropertyValues.find(x => x.statusId == this.casesByMetadataStatus);
  }

  get caseMetadataProperties(): {
    text: string;
    value: string;
  }[] {
    if (this.filteredStatusMetadataPropertyValues == undefined)
      return [];

    return this.filteredStatusMetadataPropertyValues.metadata.map(x => {
      return {
        text: x.name,
        value: x.name
      };
    })
    .sort((a, b) => (a < b ? -1 : 1)) ?? [];
  }

  get caseMetadataFilterProperties(): {
    text: string;
    value: string;
  }[] {
    const filteredProperties = this.caseMetadataProperties?.filter(
      x => x.value != this.casesByMetadataAggregatorProperty
    );

    filteredProperties.unshift({ text: "None", value: "" });

    return filteredProperties;
  }

  get caseMetadataPropertyValues() {
    const selectedFilter = this.filteredStatusMetadataPropertyValues
      ?.metadata.find(
        x => x.name == this.casesByMetadataFilterProperty
      );

    return selectedFilter
      ?.values
      .sort((a, b) => (a < b ? -1 : 1)) ?? [];
  }

  @Watch("casesByMetadataStatus")
  resetAggregate() {
    this.casesByMetadataAggregatorProperty = "";
  }

  @Watch("casesByMetadataAggregatorProperty")
  resetFilters() {
    this.casesByMetadataFilterProperty = "";
    this.casesByMetadataFilterValue = "";
  }
}
