


















import { Component, Inject, Vue } from "vue-property-decorator";
import AnalyticsService, {
  ScreeningStats,
  AnalyticsData
} from "@/services/analytics-service";

import HazcheckDetectScreeningsChart from "./data-display/hazcheck-detect-screenings-chart.vue";
import HazcheckDetectPopularRulesTable from "./data-display/hazcheck-detect-popular-rules-table.vue";
import HazcheckDetectTotals from "./data-display/hazcheck-detect-totals.vue";

@Component({
  components: {
    HazcheckDetectTotals,
    HazcheckDetectScreeningsChart,
    HazcheckDetectPopularRulesTable
  }
})
export default class HazcheckDetectAnalytics extends Vue {
  @Inject() AnalyticsService!: AnalyticsService;

  screenings: AnalyticsData<ScreeningStats> | null = null;
  loading = true;

  async mounted() {
    await this.fetchAnalytics();
  }

  async fetchAnalytics() {
    this.screenings = await this.AnalyticsService.readScreenings();
    this.loading = false;
  }
}
