































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { AllMetadataResults } from "@/components/analytics/case-management/case-management-func";
import Papaparse from "papaparse";
import  dayjs from "dayjs";

@Component({})
export default class CaseManagementMetadataDialog extends Vue {
    @PropSync("dialog") dialogSync: boolean | undefined;
    @Prop({}) disabled = true;
    @Prop({}) casesByMetadataFrom: Date | undefined;
    @Prop({}) casesByMetadataTo: Date | undefined;
    @Prop({}) metadataTableWithAllProperties: AllMetadataResults[] | undefined;
    @Prop({}) metadataTableHeaders: string[] | undefined;
    @Prop({}) casesByMetadataAggregatorProperty: string | undefined;
    @Prop({}) casesByMetadataFilterProperty: string | undefined;


    metadataTableWithAllPropertiesRow(index: number)
    {
        return this.metadataTableWithAllProperties?.map(x => x.Data[index].Total)
    }

    closeDialog() {
        this.dialogSync = false;
    }

    async exportToCsv() {

      const csvFile = await this.prepareContentForCsv();

      this.downloadCsv(csvFile);    
    }

    prepareContentForCsv(){
        const data = this.metadataTableWithAllProperties?.map(x => {        
            return x.Data.map(d => {
                return { 
                    Property: d.Name, 
                    Filter: x.Filter,
                    Total: d.Total 
                }
            })
        })

        if(!data) return "";

        return Papaparse.unparse(data.flatMap(x=>x),  { escapeFormulae: true})
    }

    downloadCsv(contents: string) {
        const anchor = document.createElement("a");
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(contents);
        anchor.target = "_blank";
        anchor.download = `metadata-export-${dayjs(this.casesByMetadataFrom)?.format("YYYY-MM-DD")}-${dayjs(this.casesByMetadataTo)?.format("YYYY-MM-DD")}.csv`;
        anchor.click();
    }
}
