






















import { Component, Prop, Vue } from "vue-property-decorator";
import TotalSheet from "@/components/analytics/shared/total-sheet.vue";
import { AnalyticsData, ScreeningStats } from "@/services/analytics-service";
import dayjs from "dayjs";
import DateRange from "@/components/analytics/shared/date-range.vue";
import { AnalyticsTotals, calculateTotals } from "@/components/analytics/hazcheck-detect/hazcheck-detect-func";

@Component({
  components: {
    TotalSheet,
    DateRange,
  }
})
export default class HazcheckDetectTotals extends Vue {
  @Prop() screenings: AnalyticsData<ScreeningStats> | null = null;
  @Prop() loading!: boolean;
  screeningsFrom = dayjs().startOf('day').subtract(7, 'days').format('YYYY-MM-DD');
  screeningsTo = dayjs().startOf('day').format('YYYY-MM-DD');
  maxDays = 120;

  get totals(): AnalyticsTotals {
    return calculateTotals(
      this.screenings,
      new Date(this.screeningsFrom),
      new Date(this.screeningsTo)
    )
  }

  get totalScreenings() {
    return this.totals.totalScreenings;
  }

  get withHits() {
    return this.totals.totalScreeningsWithHits;
  }

  get lastUpdated() {
    return this.screenings?.lastUpdated;
  }
}
 
